import CookieService from "@/services/CookieService";
import CommonService, {isNotEmpty} from "@/services/CommonService";
import CommonElem from "@/services/CommonElem";

const TOKEN_QUERY = "oneTimeToken"

async function preparePrivate(userEmail, redirectUrl, sendEmail) {
    const res = await CommonService.post(
        "/private/oneTimeLogin/prepare",
        {
            userEmail,
            redirectUrl,
            sendEmail,
    })
    
    if (res.error) return
    
    if (isNotEmpty(res.oneTimeToken))
        return res.oneTimeToken
    
    return {}
}
async function login(oneTimeToken) {
    const res = await CommonService.post(
        "/public/oneTimeLogin/login",
        {
            token: oneTimeToken
    })

    if(res.error) {
        CommonElem.error("Jednorázové přihlášení se nezdařilo.")
        return false
    }

    console.log(`Úspěšně přihlášený`);

    CookieService.setCookie(CookieService.NAMES.TOKEN, res.token, Infinity)
    CookieService.setCookie(CookieService.NAMES.USER_EMAIL, "TODOemailInput", Infinity)
    CookieService.setCookie(CookieService.NAMES.CONG_NAME, res.congName, Infinity)
    CookieService.setCookie(CookieService.NAMES.USER, res.user, Infinity);

    return true
}

export default {
    preparePrivate,
    login,
    TOKEN_QUERY,
}

