import {reactive} from "vue";
import {isMobile} from "@/services/CommonService";
import CommonElem from "@/services/CommonElem";

export function hide() {
  if (!state.show)
    return

  if (isMobile)
    CommonElem.dark.hide()

  state.show = false
  state.task = {}
}

const state = reactive({
  show: false,
  style: {},
  event: {},
  task: {},
})

export default {
  state,
}