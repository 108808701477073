import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import {autoAnimatePlugin} from "@formkit/auto-animate/vue";

const app = createApp(App)

app.use(router)
    .use(autoAnimatePlugin)
    .mount('#app')

app.directive('maxLength', {
    beforeMount(el, binding, vnode) {
        el.addEventListener("input", (() => {
            if (binding.value <= el.value.length) {
                el.value = el.value.slice(0, binding.value)
            }
        }));
    }
})