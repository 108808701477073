import CookieService from "@/services/CookieService";
import CommonService from "@/services/CommonService";
import CommonElem from "@/services/CommonElem";

export default {
    isLogged() {
        return CookieService.getCookie(CookieService.NAMES.TOKEN);
    },

    async postLogin(emailInput, passwordInput) {
        return CommonService.post("/public/login", {
            email: emailInput,
            password: passwordInput,
        }).then(res => {
            if(res.error) return false

            CookieService.setCookie(CookieService.NAMES.TOKEN, res.token, Infinity);
            CookieService.setCookie(CookieService.NAMES.USER_EMAIL, emailInput, Infinity);
            CookieService.setCookie(CookieService.NAMES.CONG_NAME, res.congName, Infinity);
            CookieService.setCookie(CookieService.NAMES.USER, res.user, Infinity);
            return true;
        })
    },

    logout() {
        console.log("Logout")
        CookieService.removeCookie(CookieService.NAMES.TOKEN);
        CookieService.removeCookie(CookieService.NAMES.CONG_NAME);
        window.location.href = "/"
    },

    reset(emailInput) {
        CommonService.post("/public/reset", {
            email: emailInput,
        })
    },
}

